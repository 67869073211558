const lightBoxOptions = {
	buttons: {
		showAutoplayButton: false,
		showCloseButton: true,
		showDownloadButton: false,
		showFullscreenButton: false,
		showNextButton: false,
		showPrevButton: false,
		showThumbnailsButton: false,
	},
	thumbnails: {
		showThumbnails: false,
	},
};

export default lightBoxOptions;