import React, { useEffect, useRef, useState } from 'react'
import lightBoxOptions from '../../options/lightBoxOptions'
import Masonry from 'react-masonry-css';
import { SRLWrapper } from 'simple-react-lightbox';
import { graphql, useStaticQuery } from 'gatsby';
const Obrazy = () => {
    const data = useStaticQuery(graphql`
		query galleryQuery {
            allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
                edges {
                    node {
                        id
                        publicURL
                        name
                    }
                }
            }
	}
	`);

    const sortedGalleryImages = data.allFile.edges.sort(function (a, b) {
		return parseInt(a.node.name, 10) - parseInt(b.node.name, 10);
	});

	const [galleryImages] = useState(sortedGalleryImages);
	const [list, setList] = useState([...galleryImages.slice(0, 10)]);
	const [loadMore, setLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(galleryImages.length > 10);
	const loadRef = useRef();

	const handleObserver = (entities) => {
		const target = entities[0];
		if (target.isIntersecting) {
			setLoadMore(true);
		}
	};

	useEffect(() => {
		var options = {
			root: null,
			rootMargin: '20px',
			threshold: 1.0,
		};
		const observer = new IntersectionObserver(handleObserver, options);
		if (loadRef.current) {
			observer.observe(loadRef.current);
		}
	}, []);

	useEffect(() => {
		if (loadMore && hasMore) {
			const currentLength = list.length;
			const isMore = currentLength < galleryImages.length;
			const nextResults = isMore
				? galleryImages.slice(currentLength, currentLength + 10)
				: [];
			setList([...list, ...nextResults]);
			setLoadMore(false);
		}
	}, [loadMore, hasMore]); //eslint-disable-line

	useEffect(() => {
		const isMore = list.length < galleryImages.length;
		setHasMore(isMore);
    }, [list]); //eslint-disable-line
    
    return (
        <SRLWrapper options={lightBoxOptions}>
						<Masonry
							breakpointCols={{
								default: 4,
								1100: 3,
								700: 2,
								500: 1,
							}}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{list.map((galleryImage, index) => {
								return (
									<div
										key={'gallery-image-' + index}
										className="galleryImageWrapper"
									>
										<img
											src={galleryImage.node.publicURL}
											alt=""
											className="galleryImage"
											loading="lazy"
											width="600"
											height="400"
										/>
									</div>
								);
							})}
						</Masonry>
						<div ref={loadRef}></div>
					</SRLWrapper>
    )
}

export default Obrazy
